<template>
<b-container class="reports-container">
    <report-list :id="provider_id ? provider_id : -1" idType="provider" />
</b-container>
</template>

<script>

import ReportList from '../reports/ReportList.vue'

export default {
  name: 'ProviderReports',
  components: {
    ReportList
  },
  data(){
      return {
      }
  },
  methods: {
  },
  computed: {
    provider_id: function(){
      return JSON.parse(localStorage.getItem("provider"))?.provider_id || this.$store.state.providerPortal.selectedProviderId;
    }
  },
  beforeMount(){
    this.$store.commit("providerPortal/setActiveNavTab", 'reports');
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.reports-container {
  max-width: 3000px;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
}
</style>